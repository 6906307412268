<div class="news-feed load-more">
  <div class="loading">
    <div class="load-blocker load-1"></div>
    <div class="load-blocker load-2"></div>
    <div class="load-blocker load-3"></div>
    <div class="load-blocker load-4"></div>
    <div class="load-blocker load-5"></div>
    <div class="load-blocker load-6"></div>
    <div class="load-blocker load-7"></div>
    <div class="load-blocker load-8"></div>
    <div class="load-blocker load-9"></div>
    <div class="load-blocker load-10"></div>
    <div class="load-blocker load-11"></div>
    <div class="load-blocker load-12"></div>
    <div class="load-blocker load-13"></div>
  </div>
</div>
