import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpParams, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {exhaustMap, take} from 'rxjs/operators';
import {AppService} from '@services/app.service';

@Injectable()
export class ApiInterceptorService implements HttpInterceptor{
  constructor(private appService: AppService) {
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.appService.user.pipe(take(1), exhaustMap(user => {
      if (!user){
        return next.handle(req);
      }
      const modifiedReq = req.clone({headers: req.headers.set('Authorization', 'JWT '+ user.token)});
      return next.handle(modifiedReq);
    }));

  }

}
