<li class="nav-item dropdown">
    <a class="nav-link" data-toggle="dropdown" (click)="toggleDropdownMenu()">
        <i class="flag-icon flag-icon-tr"></i>
    </a>
    <div #dropdownMenu class="dropdown-menu dropdown-menu-right p-0">
        <a href="#" class="dropdown-item active">
            <i class="flag-icon flag-icon-tr mr-2"></i> Turkish
        </a>
        <a href="#" class="dropdown-item">
            <i class="flag-icon flag-icon-us mr-2"></i> English
        </a>
        <a href="#" class="dropdown-item">
            <i class="flag-icon flag-icon-de mr-2"></i> German
        </a>
        <a href="#" class="dropdown-item">
            <i class="flag-icon flag-icon-fr mr-2"></i> French
        </a>
        <a href="#" class="dropdown-item">
            <i class="flag-icon flag-icon-es mr-2"></i> Spanish
        </a>
    </div>
</li>
